import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./walletactivation.component.css";
// import Backpack from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
// import Button from '@mui/material/Button';
import Navbar from "../header/header.component";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import {Snackbar} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";
import {
  // TextField,
  Button,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { TextField ,Select,FormControl,InputLabel} from "@mui/material";
import { switchUrls } from "../../api/index.js";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
import imageCompression from "browser-image-compression";
import setCanvasPreview from "../../setcanvasPreview.js";
import Footer from "../footer/footer.component.jsx";
import Alert from '@mui/material/Alert';
// import Loader from "../../assets/loader.gif";

const baseURL = switchUrls("wallet");
const basegatewayURL = switchUrls("gateway");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

class LocationErrorModal extends React.Component {
  render() {
    const { open, handleClose, handleRetry } = this.props;

    return (
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px", // Adjust the width as needed
            textAlign: "center",
          }}
        >
          <p style={{ marginBottom: "15px" }}>
            Unable to retrieve your location. Please turn on your GPS.
          </p>
          <button
            onClick={handleRetry}
            style={{
              padding: "10px 15px",
              background: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Retry
          </button>
        </div>
      </Modal>
    );
  }
}

export default class BagActivation extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();

    this.state = {
      imageUrl: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      description: "",
      date_of_purchase: "",
      warranty_period: "",
      selectedOption: "Day",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      display_name: this.props.display_name || "",
      valuable_type: "Wallet",
      wallet_color: "",
      wallet_brand: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/wallet.png",
      imagemodal: false,
      crop: "",
      uploaderror: false,
      Wallet_imagelocation: null,
      imageName: "",
      showLoader: false,
      showLocationErrorModal: false,
      modalShown: false,
      retryLocation: false,
      show: false,
      showQR: false,
      tagNotFound: false,
      alreadyActivated: false,
      walletDialogOpen: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
    this.checkLocation = this.checkLocation.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
    this.walletDialogHandleClose = this.walletDialogHandleClose.bind(this);
  }

  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    // console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob(async (blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      const imageDimension = await this.imageSize(file);
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      });
      console.log(
        "image size after compression:",
        compressedFile.size / (1024 * 1024)
      );
      this.setState({
        selectedImage: compressedFile,
        imageUrl: URL.createObjectURL(compressedFile),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
        showLoader: false,
      });
    });
  }
  checkLocation = ()=>{
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
      
        if (permissionStatus.state === 'granted') {
          this.getLocation();
          // You can trigger a function here to re-fetch location data
        }
        else{
          this.setState({
            showLocationErrorModal: true,
          });
        }
      };
    });
  }
    componentWillUnmount() {
    window.removeEventListener("load", this.handlePageLoad);
  }


 getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState(
            {
              locationData: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              showLocationErrorModal: false,
              modalShown: false,
              retryLocation: false,
            },
          
          );
        },
        (error) => {
          console.error("Error getting location:", error);
          console.log(this.state.modalShown, "modal");
          this.setState({
            showLocationErrorModal: true,
            modalShown: true,
          });
      
        }
      );
    } else {
      this.setState({
        showLocationErrorModal: true,
        modalShown: true,
      });
      console.error("Geolocation is not supported by this browser.");
    }
  };

  componentDidMount() {
    // console.log(usertoken);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
    this.getLocation();
    this.checkLocation()
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
  activateTag() {
    const { tag_number } = this.state;
    const state = {
      tag_number: tag_number.toUpperCase(),
    };
    this.setState({ showLoader: true });
    const loaderTimer = setTimeout(() => {
      this.setState({ showLoader: false });
    }, 5000);

    axios
      .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
        headers: {
          Authorization: this.props.token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const productcategory = res.data.tag_data.product_category;
        sessionStorage.removeItem("tag_number");

        // Check if product category starts with "Dolphin" or "Smart"
        if (
          productcategory.startsWith("Dolphin") || 
          productcategory.startsWith("Smart")
        ) {
          this.setState({ walletDialogOpen: true });
          return;
        }

        const routes = {
          "QR PET": `/pet-activation/${tag_number}`,
          "QR Key": `/key-activation/${tag_number}`,
          "QR bag": `/bag-activation/${tag_number}`,
          "QR Bag": `/bag-activation/${tag_number}`,
          "E-Bag": `/bag-activation/${tag_number}`,
          "QR Medical": `/bag-activation/${tag_number}`,
          "QR Passport": `/passport-activation/${tag_number}`,
          "E-Passport": `/passport-activation/${tag_number}`,
          "QR Gadget": `/earphone-activation/${tag_number}`,
          "Generic": `/generic-activation/${tag_number}`,
          "QR Wallet": `/wallet-activation/${tag_number}`,
          "QR Human": `/human-activation/${tag_number}`,
          "QR HUMAN": `/human-activation/${tag_number}`,
          "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
        };

        if (routes[productcategory]) {
          window.location.href = routes[productcategory];
        }
      })
      .catch((error) => {
        clearTimeout(loaderTimer);
        this.setState({ showLoader: false });
        const responseData = error.response.data;
        console.log(responseData, "data");
        if (responseData === "Tag not found by the specified number.") {
          // this.setState({ tagNotFound: true });
          this.setState({
              tagNotFound: true,
              snackbarOpen: true,
              snackbarMessage: "Tag not found!",
              snackbarType: "warning",
            });
        } else if (responseData === "Tag is already activated.") {
          // this.setState({ show: false, alreadyActivated: true });
          this.setState({
              alreadyActivated: true,
              snackbarOpen: true,
              snackbarMessage: "Tag is already activated.",
              snackbarType: "info",
            });
        }
      });
  }
  handleCloseQRModal() {
    this.setState({ showQR: false });
  }

  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, showQR: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.showQR}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }

  async handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log("image original size:", sizeinmb);
      this.setState({ imagemodal: true });
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        isuploading: true,
        imageName: event.target.files[0].name,
      });
    } else {
      console.log("failed");
    }
  }
  handleOk = () => {
    this.setState({
      showLocationErrorModal: false,
      modalShown: true,
      retryLocation: false, // Reset retryLocation on OK click
    });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  handleChangeWarranty = (e) => {
    this.setState({
      warranty_period: `${e.target.value} ${this.state.selectedOption}`,
    });
  };
  handleSelectChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });
    const {
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      warranty_period,
      selectedOption,
      wallet_color,
      selectedImage,
      wallet_brand,
      imageUrlvector,
      vectorurl,
      display_name,
      modalShown,
      retryLocation,

    } = this.state;

    const { latitude, longitude } = this.state.locationData;
    // console.log(updatedBagImagelocation, "location of bag");
    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      description,
      date_of_purchase,
      warranty_period,
      selectedOption,
      wallet_color,
      selectedImage,
      wallet_brand,
      imageUrlvector,
      vectorurl,
      latitude,
      longitude,
      display_name,
    };
    axios
      .post(`${baseURL}/api/wallet/walletactivation`, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.data === "Tag Already Activated") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response.data,
            showLoader: false,
          });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response.data,
            showLoader: false,
          });
        } else if (response.data === "Wallet Activated Successfully") {
          if (this.state?.selectedImage != null) {
            const fd = new FormData();

            fd.append("image", this.state.selectedImage);
            fd.append("imageName", this.state.imageName);
            fd.append("tag_number", this.props.match.params.tag_number);

            axios
              .post(`${baseURL}/api/wallet/walletimageupload`, fd, {
                headers: {
                  "Content-Type": "multipart/form-data", // Set the correct content type
                },
              })
              .then((res) => {
                console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Wallet image upload Successfully",
                });
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Wallet image upload failed",
                  showLoader: false,
                });
              });
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        }
      })
      .catch((error) => {
        console.error(error.message);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Wallet Activation failed",
          showLoader: false,
        });
      });
  };
  // handleShow() {
  //   window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  // }
  handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
  walletDialogHandleClose = () => {
    this.setState({ walletDialogOpen: false });
  };

  render() {
    const {
      imageUrl,
      tag_number,
      date_of_purchase,
      warranty_period,
      valuable_type,
      description,
      wallet_brand,
      wallet_color,
      selectedOption,
      locationData,
      showLocationErrorModal,
      sidebar,
      subMenu,
      imagemodal,
      crop,
      uploaderror,
      Wallet_imagelocation,
      imageUrlvector,
      message,
      display_name,
      locationBlocked,
      show,
      setshowQR,
      tagNotFound, 
      alreadyActivated,
      walletDialogOpen,
      snackbarOpen,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const { latitude, longitude } = this.state.locationData;

    const today = () => {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();

      if (month < 10) month = "0" + month.toString();

      if (day < 10) day = "0" + day.toString();

      var today = year + "-" + month + "-" + day;

      return today;
    };

    return (
      <>
        <Navbar setName={(name) => this.setState({ display_name: name })}/>
        <div className="bagActivation-main-wrapper">
          <div className="bagActivation-left-wrapper">
            <Sidebarcontent
              handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
            />
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ showQR: true })}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
           <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1.5em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }style={{ color: "blue" }}><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  }style={{ color: "blue" }}>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>
            <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          </div>
        </div>
        <div className="bag-container">
          <div className="background-image">
            <div className="bag-activation-container">
              <p>
                <strong>Wallet Activation</strong>
              </p>

              <div className="bag-activation-form-container">
                <form
                  // onSubmit={this.submitHandler}
                  className="bag-activation-form"
                >
                  <div className="editPetDetails-user-img">
                    {imageUrl?.length ? (
                      <>
                        <img
                          alt="imageurl"
                          src={imageUrl}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : Wallet_imagelocation ? (
                      <>
                        <img
                          alt="imagelocation"
                          src={Wallet_imagelocation}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="imageyrlvector"
                          src={imageUrlvector}
                          className="editPetDetails-image"
                        />
                      </>
                    )}
                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        id="upload"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        alt="qr"
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>
                  </div>

                  <div>
                     <div className="bagactivation-label-container">
                      <TextField
                        type="text"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        name="description"
                        label="Wallet Description"
                        value={description}
                        InputLabelProps={{
                        style: { fontSize: '0.90rem' } 
                         }}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <div className="bagactivation-label-container">
                      <Tooltip
                        describeChild
                        title="This name will appear on the scan page when the tag is scanned."
                        leaveTouchDelay={3000}
                        enterTouchDelay={50}
                      >
                        <TextField
                          required
                          type="text"
                          variant="outlined"
                          name="display_name"
                          label="Display Name"
                          value={this.state.display_name}
                          InputLabelProps={{
                          style: { fontSize: '0.90rem' } 
                          }}
                          onChange={this.changeHandler}
                          className="bagactivation-flex-item"
                        />
                      </Tooltip>
                    </div>
                   <div className="bagactivation-label-container">
                      <TextField
                        type="text"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        name="wallet_brand"
                        label="Wallet Brand"
                        value={wallet_brand}
                        InputLabelProps={{
                          style: { fontSize: '0.90rem' } 
                          }}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <div className="bagactivation-label-container">
                      {/* <span className="bagactivation-label-bagdata">
                        Date of Purchase
                      </span> */}
                      <TextField
                        type="date"
                        label="Wallet Purchase Date"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        name="date_of_purchase"
                        value={date_of_purchase.substring(0, 10)}
                        onChange={this.changeHandler}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '0.90rem' } 
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>

                   

                    <div className="bagactivation-warrantylabel-container">
                      {/* <label>Warranty Period (in months)</label> */}
                      <TextField
                        type="number"
                        // className="keydetails-right-form-inputs"
                        variant="outlined"
                        maxLength="20"
                        label="Warranty Period"
                        name="warranty_period"
                        value={warranty_period.split(" ")[0]}
                        InputLabelProps={{
                        style: { fontSize: '0.90rem' } 
                         }}
                        onChange={this.handleChangeWarranty}
                      />
                      <FormControl fullWidth className="custom-warranty-duration" >
                        <InputLabel
                          id="demo-simple-select-label"
                          // style={{ marginTop: "-7px", marginLeft: "13px" }}
                        >
                          Warranty Duration
                        </InputLabel>
                        <Select
                          labelId="valuable-type-label"
                          id="valuable-type-select"
                          required
                          label="Warranty Duration"
                          InputLabelProps={{
                          style: { fontSize: '0.90rem' } 
                          }}
                          value={selectedOption}
                          onChange={this.handleSelectChange}
                          variant="outlined"
                        >
                          <MenuItem value="Day">Day</MenuItem>
                          <MenuItem value="Month">Month</MenuItem>
                          <MenuItem value="Year">Year</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="bagactivation-label-container">
                      <TextField
                        type="text"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        label="Wallet Color"
                        name="wallet_color"
                        InputLabelProps={{
                          style: { fontSize: '0.90rem' } 
                          }}
                        value={wallet_color}
                        onChange={this.changeHandler}
                      />
                    </div>

                   
                    <div className="bagactivation-btn-grp">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                        onClick={this.submitHandler}
                      >
                        SUBMIT
                      </Button>
                      <Button
                        variant="contained"
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                        className="submit-login bg-white mt-2"
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        {/* Loader */}
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button
                    onClick={() => {
                      this.setState({ showLoader: true });
                      this.handelimagecrop();
                    }}
                  >
                    Submit
                  </Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
         <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showLocationErrorModal}
        autoHideDuration={6000}
        onClose={() => this.setState({ showLocationErrorModal: false })} 
      >
        <Alert
          style={{ backgroundColor: "#90EE90" }}
          onClose={() => {this.setState({showLocationErrorModal:false})}}
          severity="success"
          sx={{ width: "100%" }}
        >
        To enhance your experience, please enable GPS. This step is optional, and you can still proceed without enabling it.
        </Alert>
      </Snackbar>
       {this.renderQRModal()}
      </>
    );
  }
}
